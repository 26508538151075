import React, { useState, useEffect } from 'react';
import './Work.scss';
import { AiFillEye } from 'react-icons/ai';
import { RiGithubFill } from "react-icons/ri";
import { LuBrainCircuit } from "react-icons/lu";
import { IoLogoJavascript, IoLogoHtml5, IoLogoCss3, IoLogoPython } from "react-icons/io5";
import { TbBrandThreejs } from "react-icons/tb";

import { FaAppStoreIos, FaSwift, FaJava } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";

import { GrReactjs } from "react-icons/gr";
import { SiSpring, SiDjango, SiMysql, SiNextdotjs, SiTypescript, SiGraphql } from "react-icons/si";
import { BiLogoMongodb, BiLogoPostgresql } from "react-icons/bi";

import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { Tooltip as ReactTooltip } from 'react-tooltip'



const Work = () => {

  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  useEffect(() => {
    const query = '*[_type =="works"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    })
  }, []);
  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
      if (item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));

      }
    }, 500);
  }

  const returnTagIcon = (tag, keyRef) => {
    const modTag = tag.slice(7);
    switch (modTag) {
      case 'AI':
        return <LuBrainCircuit className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Javascript':
        return <IoLogoJavascript className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'React':
        return <GrReactjs className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'HTML':
        return <IoLogoHtml5 className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'CSS':
        return <IoLogoCss3 className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'ThreeJS':
        return <TbBrandThreejs className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'iOS':
        return <FaAppStoreIos className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Swift':
        return <FaSwift className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Python':
        return <IoLogoPython className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Java':
        return <FaJava className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Spring':
        return <SiSpring className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Django':
        return <SiDjango className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'MySQL':
        return <SiMysql className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'MongoDB':
        return <BiLogoMongodb className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'NextJS':
        return <SiNextdotjs className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Typescript':
        return <SiTypescript className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'GraphBase':
        return <SiGraphql className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Go':
        return <FaGolang className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      case 'Postgres':
        return <BiLogoPostgresql className='icon_tech' key={keyRef} data-tooltip-id={tag} />;
      default:
        return <></>
    }
  }

  return (
    <>
      <h2 className='head-text'><span>Portfolio</span></h2>

      <div className='app__work-filter'>
        {['Backend', 'All'].map((item, index) => (

          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app_flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}

          </div>
        )

        )}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__work-portfolio'
      >
        {
          filterWork.map((work, index) => (
            <div className='app__work-item app__flex test' key={index}>
              <div className='app__work-img app__flex'>
                <img src={urlFor(work.rectImgUrl)} alt={work.name} />

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                  className='app__work-hover app__flex'
                >


                  <a href={work.codeLink} target='_blank' rel='noreferrer'>
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className='app__flex'
                    >
                      <RiGithubFill />
                    </motion.div>
                  </a>

                </motion.div>
              </div>

              <div className='app__work-content app__flex'>
                <h4 className='bold-text'>{work.title}</h4>
                <div className='link_container'>

                  <a href={work.codeLink} target='_blank' rel='noreferrer'>
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className='app__flex'
                    >
                      <RiGithubFill className='link-icon' />
                    </motion.div>
                  </a>
                </div>


                <p className='p-text' style={{ marginTop: 10 }}>{work.description}</p>
                {
                  <div className='techContainer'>
                    {
                      work.tags.slice(2).map((tag, index) => (
                        <>
                          {returnTagIcon(tag, `${tag + index}`)}


                        </>


                      ))
                    }

                  </div>

                }



              </div>
            </div>
          ))
        }

      </motion.div>
    </>
  )
}

export default AppWrap(Work, 'work');