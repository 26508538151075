import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import './Navbar.scss';
import { images } from '../../constants';
const Navbar = () => {
    const [toggle, settoggle] = useState(false)
    return (
        <nav className='app__navbar'>
            <div className='app__navbar-logo'>
                <a id='name_header' href={`#home`} onClick={() => settoggle(false)}><h1>LoganWatkins.io</h1></a>
            </div>

            <ul className='app__navbar-links'>
                {
                    ['home', 'about', 'work', 'skills', 'certifications', 'contact'].map((item) => (
                        <li className="app__flex p-text" key={`link-${item}`}>
                            <div />
                            <a href={`#${item}`}>{item}</a>
                        </li>
                    ))
                }
            </ul>


            <div className='app__navbar-menu'>
                <HiMenuAlt4 className="menu" onClick={() => settoggle(true)} />
                {
                    toggle && (
                        <motion.div
                            whileInView={{ x: [300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                        >
                            <HiX className="close_menu" onClick={() => settoggle(false)} />
                            <ul className='app__navbar-links'>

                                {['home', 'about', 'work', 'skills', 'certifications', 'contact'].map((item) => (
                                    <li key={`${item}`}>
                                        <a href={`#${item}`} onClick={() => settoggle(false)}>{item}</a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    )
                }
            </div>
        </nav>
    )
}

export default Navbar