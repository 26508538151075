import React, { useState } from 'react';
import { RiGithubFill, RiLinkedinBoxFill } from "react-icons/ri";
import {AiOutlineMail} from "react-icons/ai";
import { motion} from 'framer-motion';

import { images } from '../../constants';
import { AppWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Feel Free to Reach Out!</h2>
      <div className='contact_links'>
      <a href="https://www.linkedin.com/in/logan-watkins-b69531128/" target='_blank' rel='noreferrer'>
        <motion.div
        whileInView={{scale:[0,1]}}
        whileHover={{scale: [1,0.9]}}
        transition={{duration: 0.25}}
        className='app__flex'
        >
          <RiLinkedinBoxFill className='link__icon' id="linkedin_contact"/>
        </motion.div>
      </a>
      <a href="mailto:lw2014wa@gmail.com" target='_blank' rel='noreferrer'>
        <motion.div
        whileInView={{scale:[0,1]}}
        whileHover={{scale: [1,0.9]}}
        transition={{duration: 0.25}}
        className='app__flex'
        >
          <AiOutlineMail className='link__icon'/>
        </motion.div>
      </a>

      </div>
      
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(Footer, 'contact');