import React from 'react'
import './Header.scss';
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';


const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div 
        whileInView={{y: [-100, 0], opacity:[0,1]}}
        transition={{duration: 2.5}}
        className='app__header-info'
      >
      <h1 id = "primary-header">Hi I'm <span id = "name">Logan</span>, a software engineer</h1>
      </motion.div>
      </div>
  )
};

export default AppWrap(Header, 'home');