import React from 'react'
import { RiGithubFill, RiLinkedinBoxFill } from "react-icons/ri";
const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href='https://github.com/lowat/'>
        <div>
              <RiGithubFill/>
        </div>
      </a>
      <a href='https://www.linkedin.com/in/logan-watkins-b69531128/'>
        <div>
                <RiLinkedinBoxFill/>
        </div>
        </a>

    </div>
  )
}

export default SocialMedia