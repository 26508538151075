import React, { useState, useEffect } from 'react';
import './Certifications.scss';
import { motion } from 'framer-motion';
import {AppWrap} from '../../wrapper';
import {urlFor, client} from '../../client';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import {AiFillEye, AiFillDashboard} from 'react-icons/ai';
import { RiGithubFill, RiGoogleFill } from "react-icons/ri";
import { LuBrainCircuit } from "react-icons/lu";
import { IoLogoJavascript, IoLogoHtml5, IoLogoCss3, IoLogoPython, IoSchool } from "react-icons/io5";
import { TbBrandThreejs, TbSchema, TbApi } from "react-icons/tb";

import { FaAppStoreIos, FaSwift, FaJava,FaDatabase, FaMeta  } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";

import { GrReactjs } from "react-icons/gr";
import { SiSpring, SiDjango, SiMysql, SiGooglesheets, SiTaichigraphics, SiMeta  } from "react-icons/si";
import { BiLogoMongodb, BiCodeAlt, BiScatterChart, BiLinkExternal } from "react-icons/bi";
import { PiAtomLight, PiGraphDuotone } from "react-icons/pi";
import { SlGraph } from "react-icons/sl";
import { GiWarpPipe } from "react-icons/gi";


const returnTagIcon = (tag, keyRef) => {

    switch(tag) {
      case 'AI': 
        return <LuBrainCircuit className='icon_tech' key ={keyRef} />;
      case 'Javascript': 
        return <IoLogoJavascript className='icon_tech' key ={keyRef} />;
      case 'React': 
        return <GrReactjs className='icon_tech' key ={keyRef} />;
      case 'HTML': 
        return <IoLogoHtml5 className='icon_tech' key ={keyRef} />;
      case 'CSS': 
        return <IoLogoCss3 className='icon_tech' key ={keyRef} />;
      case 'ThreeJS': 
        return <TbBrandThreejs className='icon_tech' key ={keyRef} />;
      case 'iOS': 
        return <FaAppStoreIos className='icon_tech' key ={keyRef} />;
      case 'Swift': 
        return <FaSwift className='icon_tech' key ={keyRef} />;
      case 'Python': 
        return <IoLogoPython className='icon_tech' key ={keyRef} />;
      case 'Java': 
        return <FaJava className='icon_tech' key ={keyRef} />;
      case 'Spring': 
        return <SiSpring className='icon_tech' key ={keyRef} />;
      case 'Django': 
        return <SiDjango className='icon_tech' key ={keyRef} />;
      case 'MySQL': 
        return <SiMysql className='icon_tech' key ={keyRef} />;
      case 'MongoDB': 
        return <BiLogoMongodb className='icon_tech' key ={keyRef} />;
      case 'AlgoExpert': 
        return <PiAtomLight className='icon_tech' key ={keyRef} />;
      case 'Code': 
        return <BiCodeAlt className='icon_tech' key ={keyRef} />;
      case 'System': 
        return <PiGraphDuotone className='icon_tech' key ={keyRef} />;
      case 'Algo': 
        return <SlGraph className='icon_tech' key ={keyRef} />;
      case 'Pipeline': 
        return <GiWarpPipe className='icon_tech' key ={keyRef} />;
      case 'Schema': 
        return <TbSchema className='icon_tech' key ={keyRef} />;
      case 'Dashboards': 
        return <AiFillDashboard className='icon_tech' key ={keyRef} />;
      case 'SQL': 
        return <FaDatabase className='icon_tech' key ={keyRef} />;
      case 'Sheets': 
        return <SiGooglesheets className='icon_tech' key ={keyRef} />;
      case 'Graphics': 
        return <SiTaichigraphics className='icon_tech' key ={keyRef} />;
      case 'Pipeline': 
        return <SiDjango className='icon_tech' key ={keyRef} />;
      case 'dataViz': 
        return <BiScatterChart className='icon_tech' key ={keyRef} />;
      case 'API': 
        return <TbApi className='icon_tech' key ={keyRef} />;
      case 'Meta': 
        return <SiMeta className='icon_tech' key ={keyRef} />;
      case 'Google': 
        return <RiGoogleFill className='icon_tech' key ={keyRef} />;
      case 'University of Texas at Austin': 
        return <IoSchool className='icon_tech' key ={keyRef} />;
      case 'GO': 
        return <FaGolang className='icon_tech' key ={keyRef} />;
      default:
        return <></>
    }
}

const Certifications = () => {
    const [brands, setBrands] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    function compare( a, b ) {
      const numA = Number(a);
      const numB = Number(b);
      console.log(numA, numB);
      return numA - numB;
    };

    const handleClick = (index) => {
      setCurrentIndex(index);
    };

    useEffect(() => {
      const query = '*[_type == "testimonials" && !(_id in path("drafts.**"))]';
      const brandsQuery = '*[_type == "brands"&& !(_id in path("drafts.**"))]';
      
      client.fetch(query).then((data) => {
        
        setTestimonials(data);
      });
  
    }, []);
  
    return (
      <>
            <h2 id = "certs_header" className="head-text">Certifications</h2>

        {testimonials.length && (
          
          <>
            <div className="app__testimonial-item app__flex">
              <div className="app__testimonial-content">
                <div className='app__info__container'>
                  <div className='app__info__text_container'>
                    <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                    <h5 className="p-text">{testimonials[currentIndex].company}</h5>
                  </div>       
                  <div className='app__info_icons'>
                    {
                      returnTagIcon(testimonials[currentIndex].company, `${testimonials[currentIndex].company}`)
                    }
                    <a href={testimonials[currentIndex].certLink} target='_blank' rel='noreferrer'>
                      <motion.div
                      whileInView={{scale:[0,1]}}
                      whileHover={{scale: [1,0.9]}}
                      transition={{duration: 0.25}}
                      className='app__flex'
                      >
                        <BiLinkExternal className='icon_tech'/>
                      </motion.div>
                    </a>
                  </div>
                </div>         
                <p className="p-text">{testimonials[currentIndex].feedback}</p>
                <div className='techContainer'>
                        {
                          testimonials[currentIndex].tags.map((tag, index) => (
                                returnTagIcon(tag, `${tag + index}`)
                            
                         ))
                        }
                        
                      </div>


              </div>
            </div>
  
            <div className="app__testimonial-btns app__flex">
              <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
                <HiChevronLeft />
              </div>
  
              <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
                <HiChevronRight />
              </div>


              
            </div>
          </>
        )}
      </>
    );
  };

export default AppWrap(Certifications, 'certifications');