import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client';
import './About.scss';

import { images } from '../../constants';
import { AppWrap } from '../../wrapper';

function compare(a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

const About = () => {

  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client.fetch(query).then((data) => setAbouts(data))
  }, []);
  abouts.sort(compare);

  return (
    <div>
      <h2 className='head-text'>
        I know that <span>Software Engineering</span><br />means moving <span>data</span> from point a to point b.
      </h2>
      <p id="about_p">Since graduating UT Austin, I've had a wide array of work experiences in multiple fortune 500 companies. From Fullstack Software Engineering at FedEx to Data Engineering at Meta, I've transported my fair share of data.</p>

      <div className='app__profiles'>
        {
          abouts.map((about, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className='app__profile-item'
              key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <h2 className='bold-text' style={{ marginTop: 20 }}>{about.title.slice(2)}</h2>
              <p className='p-text' style={{ marginTop: 10 }}>{about.description}</p>

            </motion.div>
          ))
        }
      </div>
    </div>
  )
}

export default AppWrap(About, 'about');